:root {
  --green-info: #38A160;
  --product-bg-color: white;
  --body-bg-color: var(--gray-50);
}

body.product-page {
  background: var(--body-bg-color);
}
body.product-page .card {
  border: none;
}

.item-breadcrumbs .breadcrumb-container a {
  color: var(--gray-900);
}

.carousel-control {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 2px 1px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
  width: 8%;
}
@media (max-width: 1200px) {
  .carousel-control-prev,
.carousel-control-next {
    width: 10%;
  }
}
@media (max-width: 768px) {
  .carousel-control-prev,
.carousel-control-next {
    width: 15%;
  }
}

.carousel-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carousel-content {
  max-width: 400px;
  margin-left: 5rem;
  margin-right: 5rem;
}

.shopping-cart .card {
  border: none;
}

.product-category-section .card:hover {
  box-shadow: 0px 16px 45px 6px rgba(0, 0, 0, 0.08), 0px 8px 10px -10px rgba(0, 0, 0, 0.04);
}
.product-category-section .card-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.no-image-item {
  height: 340px;
  width: 340px;
  background: var(--gray-100);
  border-radius: var(--border-radius);
  font-size: 2rem;
  color: var(--gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-card-group-section .card {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.item-card-group-section .card:hover {
  box-shadow: 0px 16px 60px rgba(0, 0, 0, 0.08), 0px 8px 30px -20px rgba(0, 0, 0, 0.04);
  transition: box-shadow 400ms;
}
.item-card-group-section .card:hover .like-action, .item-card-group-section .card:focus-within .like-action {
  visibility: visible;
}
.item-card-group-section .card:hover .btn-explore-variants, .item-card-group-section .card:focus-within .btn-explore-variants {
  visibility: visible;
}
.item-card-group-section .card-img-container {
  height: 210px;
  width: 100%;
}
.item-card-group-section .card-img {
  max-height: 210px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 1.25rem;
}
.item-card-group-section .no-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  background: var(--gray-100);
  width: 100%;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  font-size: 2rem;
  color: var(--gray-500);
}
.item-card-group-section .no-image-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: var(--gray-100);
  border-radius: var(--border-radius);
  font-size: 2rem;
  color: var(--gray-500);
  margin-top: 15px;
  margin-bottom: 15px;
}
.item-card-group-section .card-body-flex {
  display: flex;
  flex-direction: column;
}
.item-card-group-section .product-title {
  font-size: 14px;
  color: var(--gray-800);
  font-weight: 500;
}
.item-card-group-section .product-description {
  font-size: 12px;
  color: var(--text-color);
  margin: 20px 0;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.item-card-group-section .product-description p {
  margin-bottom: 0.5rem;
}
.item-card-group-section .product-category {
  font-size: 13px;
  color: var(--text-muted);
  margin: var(--margin-sm) 0;
}
.item-card-group-section .product-price {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
  margin: var(--margin-sm) 0;
  margin-bottom: auto !important;
}
.item-card-group-section .product-price .striked-price {
  font-weight: 500;
  font-size: 15px;
  color: var(--gray-500);
}
.item-card-group-section .product-info-green {
  color: var(--green-info);
  font-weight: 600;
}
.item-card-group-section .item-card {
  padding: var(--padding-sm);
  min-width: 300px;
}
.item-card-group-section .wishlist-card {
  padding: var(--padding-sm);
  min-width: 260px;
}
.item-card-group-section .wishlist-card .card-body-flex {
  display: flex;
  flex-direction: column;
}

.btn-item-filters {
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 48px;
  width: 48px;
  margin: 12px;
  border-radius: 99px;
  box-shadow: var(--shadow-lg);
  overflow: visible;
  background: red;
  z-index: 100;
  padding: 0px;
  text-align: center;
}

#products-list-area, #products-grid-area {
  padding: 0 5px;
}

.list-row {
  background-color: white;
  padding-bottom: 1rem;
  padding-top: 1.5rem !important;
  border-radius: 8px;
  border-bottom: 1px solid var(--gray-50);
}
.list-row:hover, .list-row:focus-within {
  box-shadow: 0px 16px 60px rgba(0, 0, 0, 0.08), 0px 8px 30px -20px rgba(0, 0, 0, 0.04);
  transition: box-shadow 400ms;
}
.list-row:hover .like-action-list, .list-row:focus-within .like-action-list {
  visibility: visible;
}
.list-row:hover .btn-explore-variants, .list-row:focus-within .btn-explore-variants {
  visibility: visible;
}
.list-row .product-code {
  padding-top: 0 !important;
}
.list-row .btn-explore-variants {
  min-width: 135px;
  max-height: 30px;
  float: right;
  padding: 0.25rem 1rem;
}

[data-doctype="Item Group"] .page-header,
#page-index .page-header {
  font-size: 20px;
  font-weight: 700;
  color: var(--text-color);
}
[data-doctype="Item Group"] .filters-section .title-section,
#page-index .filters-section .title-section {
  border-bottom: 1px solid var(--table-border-color);
}
[data-doctype="Item Group"] .filters-section .filter-title,
#page-index .filters-section .filter-title {
  font-weight: 500;
}
[data-doctype="Item Group"] .filters-section .clear-filters,
#page-index .filters-section .clear-filters {
  font-size: 13px;
}
[data-doctype="Item Group"] .filters-section .filter-lookup-input,
#page-index .filters-section .filter-lookup-input {
  background-color: white;
  border: 1px solid var(--gray-300);
}
[data-doctype="Item Group"] .filters-section .filter-lookup-input:focus,
#page-index .filters-section .filter-lookup-input:focus {
  border: 1px solid var(--primary);
}
[data-doctype="Item Group"] .filters-section .filter-label,
#page-index .filters-section .filter-label {
  font-size: 11px;
  font-weight: 600;
  color: var(--gray-700);
  text-transform: uppercase;
}
[data-doctype="Item Group"] .filters-section .filter-block,
#page-index .filters-section .filter-block {
  border-bottom: 1px solid var(--table-border-color);
}
[data-doctype="Item Group"] .filters-section .checkbox .label-area,
#page-index .filters-section .checkbox .label-area {
  font-size: 13px;
  color: var(--gray-800);
}

.product-filter {
  width: 14px !important;
  height: 14px !important;
}

.discount-filter:before {
  width: 14px !important;
  height: 14px !important;
}

.list-image {
  border: none !important;
  overflow: hidden;
  max-height: 200px;
  background-color: white;
}

.product-container {
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  padding: var(--padding-md);
  background-color: var(--card-bg);
  background-color: var(--product-bg-color) !important;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.product-container .product-details {
  max-width: 50%;
}
.product-container .product-details .btn-add-to-cart {
  font-size: 14px;
}
.product-container.item-main .product-image {
  width: 100%;
}
.product-container .expand {
  max-width: 100% !important;
}
@media (max-width: 789px) {
  .product-container .product-details {
    max-width: 90% !important;
  }
  .product-container .product-details .btn-add-to-cart {
    font-size: 14px;
  }
}
.product-container .btn-add-to-wishlist svg use {
  --icon-stroke: #F47A7A;
}
.product-container .btn-view-in-wishlist svg use {
  fill: #F47A7A;
  --icon-stroke: none;
}
.product-container .product-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  padding: 0 !important;
}
.product-container .product-description {
  font-size: 13px;
  color: var(--gray-800);
}
.product-container .product-image {
  border-color: var(--table-border-color) !important;
  padding: 15px;
}
@media (max-width: var(--md-width)) {
  .product-container .product-image {
    height: 300px;
    width: 300px;
  }
}
@media (min-width: var(--lg-width)) {
  .product-container .product-image {
    height: 350px;
    width: 350px;
  }
}
.product-container .product-image img {
  -o-object-fit: contain;
     object-fit: contain;
}
.product-container .item-slideshow {
  overflow: auto;
}
@media (max-width: var(--md-width)) {
  .product-container .item-slideshow {
    max-height: 320px;
  }
}
@media (min-width: var(--lg-width)) {
  .product-container .item-slideshow {
    max-height: 430px;
  }
}
.product-container .item-slideshow-image {
  height: 4rem;
  width: 6rem;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 0.5rem;
  border: 1px solid var(--table-border-color);
  border-radius: 4px;
  cursor: pointer;
}
.product-container .item-slideshow-image:hover, .product-container .item-slideshow-image.active {
  border-color: var(--primary);
}
.product-container .item-cart .product-price {
  font-size: 22px;
  color: var(--text-color);
  font-weight: 600;
}
.product-container .item-cart .product-price .formatted-price {
  color: var(--text-muted);
  font-size: 14px;
}
.product-container .item-cart .no-stock {
  font-size: var(--text-base);
}
.product-container .item-cart .offers-heading {
  font-size: 16px !important;
  color: var(--text-color);
}
.product-container .item-cart .offers-heading .tag-icon {
  --icon-stroke: var(--gray-500);
}
.product-container .item-cart .w-30-40 {
  width: 30%;
}
@media (max-width: 992px) {
  .product-container .item-cart .w-30-40 {
    width: 40%;
  }
}
.product-container .tab-content {
  font-size: 14px;
}

.item-booking-section {
  padding-right: 0;
}
.item-booking-section .booking-header {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-800);
  display: flex;
  align-items: center;
  padding: 0;
}
.item-booking-section .booking-container {
  padding: 0.5rem;
  min-height: 0px;
}
.item-booking-section .fc-scrollgrid-section-header th,
.item-booking-section .fc-theme-standard td,
.item-booking-section .fc-theme-standard th {
  border: None;
}
.item-booking-section .fc .fc-daygrid-day {
  background-color: #fff;
  cursor: pointer;
}
.item-booking-section .fc .fc-day-disabled {
  background-color: #fff;
  border: none;
}
.item-booking-section .fc .fc-daygrid-day-top {
  min-height: 3em;
}
.item-booking-section .fc .fc-daygrid-day-number {
  margin: auto;
}
.item-booking-section .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  min-height: 0;
}
.item-booking-section .fc .fc-bg-event {
  width: 3em;
  height: 3em;
  margin-right: auto;
  margin-left: auto;
  padding: 1px 0 0;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 50%;
}
.item-booking-section .fc .fc-daygrid-bg-harness {
  background-color: #fff;
}
.item-booking-section .booking-selector h2.timeslot-options-title {
  text-align: center;
  margin-top: 0;
}
.item-booking-section .booking-selector .timeslot-options .btn {
  width: 100%;
  font-size: 1.2rem;
}
.item-booking-section .booking-selector .timeslot-options .btn.selected {
  background-color: var(--primary);
  color: var(--light);
  border: none;
}

.recommended-item-section {
  padding-right: 0;
}
.recommended-item-section .recommendation-header {
  font-size: 16px;
  font-weight: 500;
}
.recommended-item-section .recommendation-container {
  padding: 0.5rem;
  min-height: 0px;
}
.recommended-item-section .recommendation-container .r-item-image {
  min-height: 100px;
  width: 40%;
}
.recommended-item-section .recommendation-container .r-item-image .r-product-image {
  padding: 2px 15px;
}
.recommended-item-section .recommendation-container .r-item-image .no-image-r-item {
  display: flex;
  justify-content: center;
  background-color: var(--gray-200);
  align-items: center;
  color: var(--gray-400);
  margin-top: 0.15rem;
  border-radius: 6px;
  height: 100%;
  font-size: 24px;
}
.recommended-item-section .recommendation-container .r-item-info {
  font-size: 14px;
  padding-right: 0;
  padding-left: 10px;
  width: 60%;
}
.recommended-item-section .recommendation-container .r-item-info a {
  color: var(--gray-800);
  font-weight: 400;
}
.recommended-item-section .recommendation-container .r-item-info .item-price {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color);
}
.recommended-item-section .recommendation-container .r-item-info .striked-item-price {
  font-weight: 500;
  color: var(--gray-500);
}

.product-code {
  padding: 0.5rem 0;
  color: var(--text-muted);
  font-size: 14px;
}
.product-code .product-item-group {
  padding-right: 0.25rem;
  border-right: solid 1px var(--text-muted);
}
.product-code .product-item-code {
  padding-left: 0.5rem;
}

.item-configurator-dialog .modal-body {
  padding-bottom: var(--padding-xl);
}
.item-configurator-dialog .modal-body .status-area .alert {
  padding: var(--padding-xs) var(--padding-sm);
  font-size: var(--text-sm);
}
.item-configurator-dialog .modal-body .form-layout {
  max-height: 50vh;
  overflow-y: auto;
}
.item-configurator-dialog .modal-body .section-body .form-column .form-group .control-label {
  font-size: var(--text-md);
  color: var(--gray-700);
}
.item-configurator-dialog .modal-body .section-body .form-column .form-group .help-box {
  margin-top: 2px;
  font-size: var(--text-sm);
}

.item-group-slideshow .carousel-inner.rounded-carousel {
  border-radius: var(--card-border-radius);
}

.sub-category-container {
  padding-bottom: 0.5rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--table-border-color);
}
.sub-category-container .heading {
  color: var(--gray-500);
}

.scroll-categories .category-pill {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 12px;
  margin-bottom: 8px;
  background-color: #ecf5fe;
  font-size: 14px;
  border-radius: 18px;
  color: var(--blue-500);
}

.shopping-badge {
  position: relative;
  top: -10px;
  left: -12px;
  background: var(--red-600);
  align-items: center;
  height: 16px;
  width: 16px;
  font-size: 10px;
  border-radius: 50%;
}
.shopping-badge.badge-primary {
  color: #ffff;
}

.cart-animate {
  -webkit-animation: wiggle 0.5s linear;
          animation: wiggle 0.5s linear;
}

@-webkit-keyframes wiggle {
  8%, 41% {
    transform: translateX(-10px);
  }
  25%, 58% {
    transform: translate(10px);
  }
  75% {
    transform: translate(-5px);
  }
  92% {
    transform: translate(5px);
  }
  0%, 100% {
    transform: translate(0);
  }
}

@keyframes wiggle {
  8%, 41% {
    transform: translateX(-10px);
  }
  25%, 58% {
    transform: translate(10px);
  }
  75% {
    transform: translate(-5px);
  }
  92% {
    transform: translate(5px);
  }
  0%, 100% {
    transform: translate(0);
  }
}
.total-discount {
  font-size: 14px;
  color: var(--primary-color) !important;
}

#page-cart .shopping-cart-header {
  font-weight: bold;
}
#page-cart .cart-container {
  color: var(--text-color);
}
#page-cart .cart-container .frappe-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
#page-cart .cart-container .cart-items-header {
  font-weight: 600;
}
#page-cart .cart-container .cart-table tr {
  margin-bottom: 1rem;
}
#page-cart .cart-container .cart-table th, #page-cart .cart-container .cart-table tr, #page-cart .cart-container .cart-table td {
  border-color: var(--border-color);
  border-width: 1px;
}
#page-cart .cart-container .cart-table th {
  font-weight: normal;
  font-size: 13px;
  color: var(--text-muted);
  padding: var(--padding-sm) 0;
}
#page-cart .cart-container .cart-table td {
  padding: var(--padding-sm) 0;
  color: var(--text-color);
}
#page-cart .cart-container .cart-table .cart-item-image {
  width: 20%;
  min-width: 100px;
}
#page-cart .cart-container .cart-table .cart-item-image img {
  max-height: 112px;
}
#page-cart .cart-container .cart-table .cart-items .item-title {
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}
#page-cart .cart-container .cart-table .cart-items .item-subtitle {
  color: var(--text-muted);
  font-size: 13px;
}
#page-cart .cart-container .cart-table .cart-items .item-subtotal {
  font-size: 14px;
  font-weight: 500;
}
#page-cart .cart-container .cart-table .cart-items .sm-item-subtotal {
  font-size: 14px;
  font-weight: 500;
  display: none;
}
@media (max-width: 992px) {
  #page-cart .cart-container .cart-table .cart-items .sm-item-subtotal {
    display: unset !important;
  }
}
#page-cart .cart-container .cart-table .cart-items .item-rate {
  font-size: 13px;
  color: var(--text-muted);
}
#page-cart .cart-container .cart-table .cart-items .free-tag {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--dark-green-50);
}
#page-cart .cart-container .cart-table .cart-items textarea {
  width: 80%;
  height: 60px;
  font-size: 14px;
}
#page-cart .cart-container .cart-table .cart-tax-items .item-grand-total {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}
@media (max-width: 992px) {
  #page-cart .cart-container .cart-table .column-sm-view {
    display: none !important;
  }
}
#page-cart .cart-container .cart-table .item-column {
  width: 50%;
}
@media (max-width: 992px) {
  #page-cart .cart-container .cart-table .item-column {
    width: 70%;
  }
}
#page-cart .cart-container .cart-table .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: right;
  cursor: pointer;
  margin-top: 0.25rem;
  justify-content: center;
}
#page-cart .cart-container .cart-table .remove-cart-item-logo {
  margin-top: 2px;
  margin-left: 2.2px;
  fill: var(--gray-700) !important;
}
#page-cart .cart-container .cart-table .modify-booking-btn {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  background-color: var(--gray-100);
  float: right;
  cursor: pointer;
  margin-top: 0.25rem;
  justify-content: center;
  margin-right: 2px;
}
#page-cart .cart-container .cart-table .modify-booking-btn a {
  font-weight: 300;
  color: var(--gray-700);
  text-decoration: none;
}
#page-cart .cart-container .cart-payment-addresses hr {
  border-color: var(--border-color);
}
#page-cart .cart-container .payment-summary h6 {
  padding-bottom: 1rem;
  border-bottom: solid 1px var(--gray-200);
}
#page-cart .cart-container .payment-summary table {
  font-size: 14px;
}
#page-cart .cart-container .payment-summary table td {
  padding: 0;
  padding-top: 0.35rem !important;
  border: none !important;
}
#page-cart .cart-container .payment-summary table.grand-total {
  border-top: solid 1px var(--gray-200);
}
#page-cart .cart-container .payment-summary .bill-label {
  color: var(--gray-600);
}
#page-cart .cart-container .payment-summary .bill-content {
  font-weight: 500;
}
#page-cart .cart-container .payment-summary .bill-content.net-total {
  font-size: 16px;
  font-weight: 600;
}
#page-cart .cart-container .payment-summary .btn-coupon-code {
  font-size: 14px;
  border: dashed 1px var(--gray-400);
  box-shadow: none;
}
#page-cart .cart-container .number-spinner {
  width: 75%;
  min-width: 105px;
}
#page-cart .cart-container .number-spinner .cart-btn {
  border: none;
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
#page-cart .cart-container .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
#page-cart .cart-container .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}
#page-cart .cart-container .place-order-container .btn-place-order {
  float: right;
}
#page-cart .t-and-c-container {
  padding: 1.5rem;
}
#page-cart .t-and-c-terms {
  font-size: 14px;
}
#page-cart .form-control {
  border-width: 1px;
  border-color: var(--gray-100);
  border-style: solid;
}

.no-image-cart-item {
  max-height: 112px;
  display: flex;
  justify-content: center;
  background-color: var(--gray-200);
  align-items: center;
  color: var(--gray-400);
  margin-top: 0.15rem;
  border-radius: 6px;
  height: 100%;
  font-size: 24px;
}

.cart-empty.frappe-card {
  min-height: 76vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cart-empty.frappe-card .cart-empty-message {
  font-size: 18px;
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
}

.address-card .card-title {
  font-size: 14px;
  font-weight: 500;
}
.address-card .card-body {
  max-width: 80%;
}
.address-card .card-text {
  font-size: 13px;
  color: var(--gray-700);
}
.address-card .card-link {
  font-size: 13px;
}
.address-card .card-link svg use {
  stroke: var(--primary-color);
}
.address-card .btn-change-address {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  box-shadow: none;
}

.address-header {
  margin-top: 0.15rem;
  padding: 0;
}

.btn-new-address {
  float: right;
  font-size: 15px !important;
  color: var(--primary-color) !important;
}

.btn-new-address:hover, .btn-change-address:hover {
  color: var(--primary-color) !important;
}

.modal .address-card .card-body {
  padding: var(--padding-sm);
  border-radius: var(--border-radius);
  border: 1px solid var(--dark-border-color);
}

.cart-indicator {
  position: absolute;
  text-align: center;
  width: 22px;
  height: 22px;
  left: calc(100% - 40px);
  top: 22px;
  border-radius: 66px;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  background: white;
  color: var(--primary-color);
  --icon-stroke: var(--green-info);
  font-size: 14px;
}
.cart-indicator.list-indicator {
  position: unset;
  margin-left: auto;
}

.like-action {
  visibility: hidden;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 28px;
  height: 28px;
  left: 20px;
  top: 20px;
  /* White */
  background: white;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  border-radius: 66px;
}
.like-action.like-action-wished {
  visibility: visible !important;
}
@media (max-width: 992px) {
  .like-action {
    visibility: visible !important;
  }
}

.like-action-list {
  visibility: hidden;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 28px;
  height: 28px;
  left: 20px;
  top: 0;
  /* White */
  background: white;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  border-radius: 66px;
}
.like-action-list.like-action-wished {
  visibility: visible !important;
}
@media (max-width: 992px) {
  .like-action-list {
    visibility: visible !important;
  }
}

.like-action-item-fp {
  visibility: visible !important;
  position: unset;
  float: right;
}

.like-animate {
  -webkit-animation: expand cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.6s forwards 1;
          animation: expand cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.6s forwards 1;
}

@-webkit-keyframes expand {
  30% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes expand {
  30% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.not-wished {
  cursor: pointer;
  --icon-stroke: #F47A7A !important;
}
.not-wished:hover {
  fill: #F47A7A;
}

.wished {
  --icon-stroke: none;
  fill: #F47A7A !important;
}

.list-row-checkbox:before {
  display: none;
}
.list-row-checkbox:checked:before {
  display: block;
  z-index: 1;
}

.btn-explore-variants {
  visibility: hidden;
  box-shadow: none;
  margin: var(--margin-sm) 0;
  width: 90px;
  max-height: 50px;
  flex: none;
  transition: 0.3s ease;
  color: white;
  background-color: var(--orange-500);
  border: 1px solid var(--orange-500);
  font-size: 13px;
}
.btn-explore-variants:hover {
  color: white;
}

.btn-add-to-cart-list {
  box-shadow: none;
  margin: var(--margin-sm) 0;
  max-height: 50px;
  flex: none;
  transition: 0.3s ease;
  font-size: 13px;
}
.btn-add-to-cart-list:hover {
  color: white;
}
@media (max-width: 992px) {
  .btn-add-to-cart-list {
    visibility: visible !important;
  }
}

.go-to-cart-grid {
  max-height: 30px;
  margin-top: 1rem !important;
}

.go-to-cart {
  max-height: 30px;
  float: right;
}

.remove-wish {
  background-color: white;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--gray-100);
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
}

.wish-removed {
  display: none;
}

.item-website-specification {
  font-size: 0.875rem;
}
.item-website-specification .product-title {
  font-size: 18px;
}
.item-website-specification .table {
  width: 70%;
}
.item-website-specification td {
  border: none !important;
}
.item-website-specification .spec-label {
  color: var(--gray-600);
}
.item-website-specification .spec-content {
  color: var(--gray-800);
}

.reviews-full-page {
  padding: 1rem 2rem;
}

.ratings-reviews-section {
  border-top: 1px solid #E2E6E9;
  padding: 0.5rem 1rem;
}

.reviews-header {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-800);
  display: flex;
  align-items: center;
  padding: 0;
}

.btn-write-review {
  float: right;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 400;
  border: none !important;
  box-shadow: none;
  color: var(--gray-900);
  background-color: var(--gray-100);
}
.btn-write-review:hover {
  box-shadow: var(--btn-shadow);
}

.btn-view-more {
  font-size: 14px;
}

.rating-summary-section {
  display: flex;
}

.rating-summary-title {
  margin-top: 0.15rem;
  font-size: 18px;
}

.rating-summary-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px var(--gray-100);
}

.user-review-title {
  margin-top: 0.15rem;
  font-size: 15px;
  font-weight: 600;
}

.rating {
  --star-fill: var(--gray-300);
}
.rating .star-hover {
  --star-fill: var(--yellow-100);
}
.rating .star-click {
  --star-fill: var(--yellow-300);
}

.ratings-pill {
  background-color: var(--gray-100);
  padding: 0.5rem 1rem;
  border-radius: 66px;
}

.review {
  max-width: 80%;
  line-height: 1.6;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #E2E6E9;
}

.review-signature {
  display: flex;
  font-size: 13px;
  color: var(--gray-500);
  font-weight: 400;
}
.review-signature .reviewer {
  padding-right: 8px;
  color: var(--gray-600);
}

.rating-progress-bar-section {
  padding-bottom: 2rem;
}
.rating-progress-bar-section .rating-bar-title {
  margin-left: -15px;
}
.rating-progress-bar-section .rating-progress-bar {
  margin-bottom: 4px;
  height: 7px;
  margin-top: 6px;
}
.rating-progress-bar-section .rating-progress-bar .progress-bar-cosmetic {
  background-color: var(--gray-600);
  border-radius: var(--border-radius);
}

.offer-container {
  font-size: 14px;
}

#search-results-container {
  border: 1px solid var(--gray-200);
  padding: 0.25rem 1rem;
}
#search-results-container .category-chip {
  background-color: var(--gray-100);
  border: none !important;
  box-shadow: none;
}
#search-results-container .recent-search {
  padding: 0.5rem 0.5rem;
  border-radius: var(--border-radius);
}
#search-results-container .recent-search:hover {
  background-color: var(--gray-100);
}

#search-box {
  background-color: white;
  height: 100%;
  padding-left: 2.5rem;
  border: 1px solid var(--gray-200);
}

.search-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
}

#toggle-view {
  float: right;
}
#toggle-view .btn-primary {
  background-color: var(--gray-600);
  box-shadow: 0 0 0 0.2rem var(--gray-400);
}

.placeholder-div {
  height: 80%;
  width: -webkit-fill-available;
  padding: 50px;
  text-align: center;
  background-color: #F9FAFA;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.placeholder {
  font-size: 72px;
}

[data-path=cart] .modal-backdrop {
  background-color: var(--gray-50);
}

.item-thumb {
  height: 50px;
  max-width: 80px;
  min-width: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}

.brand-line {
  color: gray;
}

.btn-next, .btn-prev {
  font-size: 14px;
}

.alert-error {
  color: #e27a84;
  background-color: #fff6f7;
  border-color: #f5c6cb;
}

.font-md {
  font-size: 14px !important;
}

.in-green {
  color: var(--green-info) !important;
  font-weight: 500;
}

.has-stock {
  font-weight: 400 !important;
}

.out-of-stock {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F47A7A;
}

.mt-minus-2 {
  margin-top: -2rem;
}

.mt-minus-1 {
  margin-top: -1rem;
}

.item-card .card {
  border: none;
}

@media screen and (min-width: 567px) {
  #page-order .main-column .page-content-wrapper .breadcrumb-container {
    padding-left: var(--padding-sm);
  }
}
#page-order .main-column .page-content-wrapper .container.my-4 {
  background-color: var(--fg-color);
}
@media screen and (min-width: 567px) {
  #page-order .main-column .page-content-wrapper .container.my-4 {
    padding: 1.25rem 1.5rem;
    border-radius: var(--border-radius-md);
    box-shadow: var(--card-shadow);
  }
}

@media screen and (max-width: 567px) {
  .indicator-container {
    padding-bottom: 0.8rem;
  }
}

.order-items {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--border-color);
  color: var(--gray-700);
}
@media screen and (max-width: 567px) {
  .order-items {
    align-items: flex-start !important;
  }
}
@media screen and (max-width: 567px) {
  .order-items .col-2 {
    flex: auto;
    max-width: 28%;
  }
}
.order-items .order-item-name {
  font-size: var(--text-base);
  font-weight: 500;
}
.order-items .btn:focus,
.order-items .btn:hover {
  background-color: var(--control-bg);
}
@media screen and (max-width: 567px) {
  .order-items .col-6 {
    max-width: 100%;
  }
}
.order-items .col-6.order-item-name {
  font-size: var(--text-base);
}

.item-grand-total {
  font-size: var(--text-base);
}

.list-item-name,
.item-total,
.order-container,
.order-qty {
  font-size: var(--text-md);
}

@media screen and (max-width: 567px) {
  .d-s-n {
    display: none;
  }
}

@media screen and (min-width: 567px) {
  .d-l-n {
    display: none;
  }
}

.border-btm {
  border-bottom: 1px solid var(--border-color);
}

.order-taxes {
  display: flex;
}
@media screen and (min-width: 567px) {
  .order-taxes {
    justify-content: flex-end;
  }
}
.order-taxes .col-4 {
  padding-right: 0;
}
.order-taxes .col-4 .col-8 {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 567px) {
  .order-taxes .col-4 {
    padding-left: 0;
    flex: auto;
    max-width: 100%;
  }
}

.filter-options {
  max-height: 300px;
  overflow: auto;
}

.address-card {
  cursor: pointer;
  position: relative;
}
.address-card .check {
  display: none;
}
.address-card.active {
  border-color: var(--primary);
}
.address-card.active .check {
  display: inline-flex;
}

.check {
  display: inline-flex;
  padding: 0.25rem;
  background: var(--primary);
  color: white;
  border-radius: 50%;
  font-size: 12px;
  width: 24px;
  height: 24px;
}

.website-list {
  background-color: var(--fg-color);
  padding: 0 var(--padding-lg);
  border-radius: var(--border-radius-md);
}
@media screen and (max-width: 567px) {
  .website-list {
    margin-left: -2rem;
  }
}
.website-list.result {
  border-bottom: 1px solid var(--border-color);
}

.transaction-list-item {
  padding: 1rem 0;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
.transaction-list-item:only-child, .transaction-list-item:last-child {
  border: 0;
}
.transaction-list-item a.transaction-item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  opacity: 0;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 0;
}

.place-order-container {
  text-align: right;
}

.kb-card .card-body > .card-title {
  line-height: 1.3;
}

.list-item-name, .item-total {
  font-size: var(--font-size-sm);
}

@media screen and (max-width: 567px) {
  .items-preview {
    margin-top: 1rem;
  }
}

.body .container {
  background: var(--body-bg-color);
}

.current-subscription-table table {
  border: none;
}

.subscription-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 1rem;
  grid-auto-rows: 1fr;
}
.subscription-list .card-footer {
  background-color: transparent;
  border-top: none;
}

.booking-page .resources-list {
  justify-content: center;
}
.booking-page .form-section .section-head {
  margin-bottom: 5px;
}